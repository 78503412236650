import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <noscript
          aria-hidden="true"
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PJSTS9Z" height="0" width="0" style="display:none;visibility:hidden" title="Google Tag Manager">Google Tag Manager</iframe>'
          }}
        />
        <header className="main-header wrapper">
          <h1 className="logo aligncenter">
            <a href="/">Rovi - Supositorios de glicerina</a>
          </h1>
          <a href="#" className="btn-menu" />
        </header>
        <div className="main-menu">
          <div className="wrapper">
            <div className="row">
              <nav className="main-nav">
                <ul
                  className="inline-list alignleft nice-menu nice-menu-right nice-menu-main-menu"
                  id="nice-menu-1"
                >
                  <li className="menu-218 menu-path-front first odd ">
                    <a href="/" title>
                      Inicio
                    </a>
                  </li>
                  <li className="menu-308 menu-path-node-1  even ">
                    <a href="/por-que-rovi/">Nuestra marca</a>
                  </li>
                  <li className="menu-313 menuparent  menu-path-node-2  odd ">
                    <a href="/supositorios-de-glicerina/">
                      Supositorios
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-314 menu-path-node-3 first odd ">
                        <a href="/supositorios-de-glicerina/que-son/">
                          ¿Qué son los supositorios de glicerina?
                        </a>
                      </li>
                      <li className="menu-315 menu-path-node-4  even ">
                        <a href="/supositorios-de-glicerina/origen-vegetal/">
                          La Glicerina: origen vegetal
                        </a>
                      </li>
                      <li className="menu-316 menu-path-node-5  odd last">
                        <a href="/supositorios-de-glicerina/ventajas/">
                          Ventajas
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-317 menuparent  menu-path-node-6  even ">
                    <a href="/productos/">
                      Productos
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-319 menu-path-node-7 first odd ">
                        <a href="/productos/rovi-adultos/">Adultos</a>
                      </li>
                      <li className="menu-318 menu-path-node-8  even ">
                        <a href="/productos/rovi-ninos/">Niños</a>
                      </li>
                      {/* <li className="menu-320 menu-path-node-9  odd last">
                        <a href="/productos/">Lactantes</a>
                      </li> */}
                    </ul>
                  </li>
                  <li className="menu-349 menuparent  menu-path-node-10 active  odd ">
                    <a href="/estrenimiento/" className="active">
                      Estreñimiento
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-350 menu-path-node-11 first odd ">
                        <a href="/estrenimiento/que-es/">¿Qué es?</a>
                      </li>
                      <li className="menu-351 menu-path-node-12  even ">
                        <a href="/estrenimiento/tipos/">
                          Tipos de estreñimiento
                        </a>
                      </li>
                      <li className="menu-352 menu-path-node-13  odd ">
                        <a href="/estrenimiento/causas/">
                          Causas del estreñimiento
                        </a>
                      </li>
                      <li className="menu-353 menu-path-node-14  even last">
                        <a href="/estrenimiento/mitos/">Mitos</a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-354 menuparent  menu-path-node-15  even last">
                    <a href="/ninos/">
                      Los niños y el estreñimiento
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-360 menuparent  menu-path-node-21 first odd ">
                        <a href="/ninos/estrenimiento-lactantes/">
                          Lactantes
                          <span className="icon icon-v-down" />
                        </a>
                        <ul>
                          <li className="menu-361 menu-path-node-22 first odd ">
                            <a href="/ninos/estrenimiento-lactantes/causas/">
                              Causas
                            </a>
                          </li>
                          <li className="menu-362 menu-path-node-23  even ">
                            <a href="/ninos/estrenimiento-lactantes/test-escala-bristol/">
                              Test
                            </a>
                          </li>
                          <li className="menu-363 menu-path-node-24  odd ">
                            <a href="/ninos/estrenimiento-lactantes/recomendaciones/">
                              Recomendaciones
                            </a>
                          </li>
                          <li className="menu-364 menu-path-node-25  even last">
                            <a href="/ninos/estrenimiento-lactantes/alimentacion/">
                              Alimentación
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-355 menuparent  menu-path-node-16  even last">
                        <a href="/ninos/estrenimiento-infantil/">
                          Niños
                          <span className="icon icon-v-down" />
                        </a>
                        <ul>
                          <li className="menu-356 menu-path-node-17 first odd ">
                            <a href="/ninos/estrenimiento-infantil/causas/">
                              Causas
                            </a>
                          </li>
                          <li className="menu-357 menu-path-node-18  even ">
                            <a href="/ninos/estrenimiento-infantil/test-escala-bristol/">
                              Test
                            </a>
                          </li>
                          <li className="menu-358 menu-path-node-19  odd ">
                            <a href="/ninos/estrenimiento-infantil/recomendaciones/">
                              Recomendaciones
                            </a>
                          </li>
                          <li className="menu-359 menu-path-node-20  even last">
                            <a href="/ninos/estrenimiento-infantil/alimentacion/">
                              Alimentación
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="breadcrumbs">
          <div className="wrapper">
            <div className="row">
              <ul className="inline-list l-grid-9">
                <li>
                  <a href="/">Inicio</a>
                </li>
                <li>Estreñimiento</li>
              </ul>{" "}
            </div>
          </div>
        </div>
        <div className="main-content wrapper">
          <div className="row">
            <div className="l-grid-9">
              <h1>estreñimiento</h1>
              <p className="vs-3">
                El estreñimiento es uno de los trastornos digestivos más comunes
                de la población que afecta sobre todo a las mujeres. Puede
                manifestarse de varias formas, la disminución del tránsito
                intestinal y/o de la frecuencia de la eliminación fecal son
                algunas de ellas. Las causas por las que se produce el
                estreñimiento son muy variadas, siendo muy frecuentes las
                relacionadas con el estilo de vida. A continuación, te
                detallamos más información sobre el estreñimiento.
              </p>
            </div>
          </div>
          <div className="row vs-1">
            <div className="crosslink crosslink-v framed one-quarter">
              <div>
                <img
                  src="/sites/all/themes/custom/rovi/images/html/xlink-que-es-estrenimiento.jpg"
                  alt
                  className="round"
                />
              </div>
              <div className="crosslink-content">
                <p>¿Qué es?</p>
                <a href="/estrenimiento/que-es/" className="btn">
                  saber más
                </a>
              </div>
            </div>
            <div className="s-block vs-1" />
            <div className="crosslink crosslink-v framed one-quarter m-prefix-1">
              <div>
                <img
                  src="/sites/all/themes/custom/rovi/images/html/xlink-tipos.jpg"
                  alt
                  className="round"
                />
              </div>
              <div className="crosslink-content">
                <p>Tipos</p>
                <a href="/estrenimiento/tipos/" className="btn">
                  saber más
                </a>
              </div>
            </div>
            <div className="s-block vs-1" />
            <div className="crosslink crosslink-v framed one-quarter">
              <div>
                <img
                  src="/sites/all/themes/custom/rovi/images/html/aside-causas.jpg"
                  alt
                  className="round"
                />
              </div>
              <div className="crosslink-content">
                <p>Causas</p>
                <a href="/estrenimiento/causas/" className="btn">
                  saber más
                </a>
              </div>
            </div>
            <div className="s-block vs-1" />
            <div className="crosslink crosslink-v framed one-quarter m-prefix-1">
              <div>
                <img
                  src="/sites/all/themes/custom/rovi/images/html/xlink-mitos-alt.jpg"
                  alt
                  className="round"
                />
              </div>
              <div className="crosslink-content">
                <p>Mitos</p>
                <a href="/estrenimiento/mitos/" className="btn">
                  saber más
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="legales">
          <nav className="wrapper">
            <ul className="inline-list aligncenter">
              <li>
                <a href="/aviso-legal/">Aviso legal</a>
              </li>
              <li>
                <a href="/politica-de-privacidad/">Política de privacidad</a>
              </li>
              <li>
                <a href="/politica-cookies/">Política de cookies</a>
              </li>
              <li>
                <a href="/mapa-del-sitio/">Mapa del sitio</a>
              </li>
              <li>
                <a href="/contacto/">Contacto</a>
              </li>
              <li>
                <a href="/preguntas-frecuentes-estrenimiento/">FAQs</a>
              </li>
              <li>
                <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">
                  Configuración de cookies
                </a>
              </li>
            </ul>{" "}
          </nav>
        </div>
        <footer className="wrapper">
          <nav className="footer-seo row">
            <div className="l-grid-2 m-one-third center">
              <ul className="nivel-1">
                <li>
                  <a href="/por-que-rovi/">Nuestra marca</a>
                </li>
                <li className="vs-3">
                  <a href="/supositorios-de-glicerina/">Supositorios</a>
                  <ul className="nivel-2">
                    <li>
                      <a href="/supositorios-de-glicerina/que-son/">
                        ¿Qué son los supositorios de glicerina?
                      </a>
                    </li>
                    <li>
                      <a href="/supositorios-de-glicerina/origen-vegetal/">
                        La glicerina: origen vegetal
                      </a>
                    </li>
                    <li>
                      <a href="/supositorios-de-glicerina/ventajas/">
                        Ventajas
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="l-grid-2 m-one-third center">
              <ul className="nivel-1">
                <li>
                  <a href="/productos/">Productos</a>
                  <ul className="nivel-2">
                    <li>
                      <a href="/productos/rovi-adultos/">Adultos</a>
                    </li>
                    <li>
                      <a href="/productos/rovi-ninos/">Niños</a>
                    </li>
                    {/* <li>
                      <a href="/productos/">Lactantes</a>
                    </li> */}
                  </ul>
                </li>
                <li className="vs-3">
                  <a href="/estrenimiento/">estreñimiento</a>
                  <ul className="nivel-2">
                    <li>
                      <a href="/estrenimiento/que-es/">¿Qué es?</a>
                    </li>
                    <li>
                      <a href="/estrenimiento/tipos/">Tipos</a>
                    </li>
                    <li>
                      <a href="/estrenimiento/causas/">Causas</a>
                    </li>
                    <li>
                      <a href="/estrenimiento/mitos/">Mitos</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="l-grid-2 m-one-third center">
              <ul className="nivel-1">
                <li>
                  <a href="/ninos/">Los niños y el estreñimiento</a>
                  <ul className="nivel-1">
                    <li>
                      <a href="/ninos/estrenimiento-infantil/">Niños</a>
                      <ul className="nivel-2">
                        <li>
                          <a href="/ninos/estrenimiento-infantil/causas/">
                            Causas
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-infantil/test-escala-bristol/">
                            Test
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-infantil/recomendaciones/">
                            Recomendaciones
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-infantil/alimentacion/">
                            Alimentación
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="/ninos/estrenimiento-lactantes/">Lactantes</a>
                      <ul className="nivel-2">
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/causas/">
                            Causas
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/test-escala-bristol/">
                            Test
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/recomendaciones/">
                            Recomendaciones
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/alimentacion/">
                            Alimentación
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="copy l-grid-3 m-full-width">
              <img
                src="/sites/all/themes/custom/rovi/images/html/logo-footer.png"
                alt="logo Rovi"
              />
              <p className="vs-2">
                <strong>©JNTL Consumer Health (Spain), S.L.</strong>
              </p>
              <p className="vs-2">
                Este sitio web está publicado
                <br className="m-hidden" />
                por JNTL Consumer Health (Spain), S.L.
                <br className="m-hidden" />
                único responsable de su contenido.
                <br />
                Está dirigida a visitantes de España.
              </p>
              <p className="semi-bold vs-2">Última actualización: marzo 2024</p>
            </div>
          </nav>
        </footer>
        <script src="/sites/all/themes/custom/rovi/js/vendor/jquery.min.js" />
        <script src="/sites/all/themes/custom/rovi/js/vendor/jquery-ui.min.js" />
        <script src="/sites/all/themes/custom/rovi/js/vendor/picturefill.min.js" />
        <script src="/sites/all/themes/custom/rovi/js/main.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nvar scripts_loaded = false;\nfunction setCookie(name , state, days) {\ndate = new Date();\ndate.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));\nexpiresDate = date.toGMTString();\nwindow.document.cookie = name +'='+ state +'; expires=' + expiresDate + \"; path=/\";\n}\nfunction loadScripts() {\nscripts_loaded = true;\n}\nfunction jnjAcceptCookiesPolicy( e ) {\nsetCookie('jnj-cookies-policy', 'true', 1000);\nloadScripts();\nif ( $('#cookie-policy').length > 0 ) {\n$('#cookie-policy').remove();\n}\n}\n$(document).ready(function() {\n$(document).one( 'click', '#btn-accept-cookies-box', jnjAcceptCookiesPolicy );\n$(document).one('click', '#btn-close-cookies-box', function() {\nif ( $('#cookie-policy').length > 0 ) {\n$('#cookie-policy').remove();\n}\n});\n});\n"
          }}
        />
      </div>
    );
  }
}

export default Page;

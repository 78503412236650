/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html className="no-js" lang="es" />
          <body className="html not-front not-logged-in no-sidebars page-node page-node- page-node-10 node-type-page" />
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta
            name="description"
            content="Descubre toda la información sobre el estreñimiento: los tipos, las causas y los mitos. Entra y consulta nuestra información"
          />
          <meta name="generator" content="Drupal 7 (https://www.drupal.org)" />
          <link
            rel="canonical"
            href="http://supositoriosrovi.es/estrenimiento/"
          />
          <meta property="og:site_name" content="Supositorios Rovi" />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content="http://supositoriosrovi.es/estrenimiento"
          />
          <meta property="og:title" content="Estreñimiento" />
          <meta
            property="og:description"
            content="estreñimientoEl estreñimiento es uno de los trastornos digestivos más comunes de la población que afecta sobre todo a las mujeres. Puede manifestarse de varias formas, la disminución del tránsito intestinal y/o de la frecuencia de la eliminación fecal son algunas de ellas. Las causas por las que se produce el estreñimiento son muy variadas, siendo muy frecuentes las relacionadas con el estilo de vida. A continuación, te detallamos más información sobre el estreñimiento."
          />
          <meta
            property="og:updated_time"
            content="2014-08-06T14:26:01+02:00"
          />
          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:url"
            content="http://supositoriosrovi.es/estrenimiento"
          />
          <meta name="twitter:title" content="Estreñimiento" />
          <meta
            name="twitter:description"
            content="estreñimientoEl estreñimiento es uno de los trastornos digestivos más comunes de la población que afecta sobre todo a las mujeres. Puede manifestarse de varias formas, la disminución del tránsito"
          />
          <meta
            property="article:published_time"
            content="2014-07-24T10:30:22+02:00"
          />
          <meta
            property="article:modified_time"
            content="2014-08-06T14:26:01+02:00"
          />
          <title>Estreñimiento - Supositorios Rovi</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link
            rel="icon"
            href="/sites/all/themes/custom/rovi/favicon.ico"
            type="image/x-icon"
          />
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            data-document-language="true"
            type="text/javascript"
            charset="UTF-8"
            data-domain-script="ce89968f-1a4e-40f9-9911-7a83dc1a8ccd"
          />
          <link
            rel="stylesheet"
            href="/sites/all/themes/custom/rovi/css/styles.css"
          />
          <link
            rel="stylesheet"
            href="/sites/all/themes/custom/rovi/css/drupal-hacks.css"
          />
          <style
            type="text/css"
            media="all"
            cssText='
@import url("/modules/system/system.base.css?q933yp");
@import url("/modules/system/system.menus.css?q933yp");
@import url("/modules/system/system.messages.css?q933yp");
@import url("/modules/system/system.theme.css?q933yp");
'
          />
          <style
            type="text/css"
            media="all"
            cssText='
@import url("/modules/comment/comment.css?q933yp");
@import url("/modules/field/theme/field.css?q933yp");
@import url("/modules/node/node.css?q933yp");
@import url("/modules/search/search.css?q933yp");
@import url("/modules/user/user.css?q933yp");
@import url("/sites/all/modules/contrib/views/css/views.css?q933yp");
'
          />
          <style
            type="text/css"
            media="all"
            cssText='
@import url("/sites/all/modules/contrib/ctools/css/ctools.css?q933yp");
'
          />


          <script type="text/javascript" src="/misc/jquery.js?v=1.4.4" />
          <script type="text/javascript" src="/misc/jquery.once.js?v=1.2" />
          <script type="text/javascript" src="/misc/drupal.js?q933yp" />
          <script
            type="text/javascript"
            src="/sites/default/files/languages/es_4TvYutQLdy_bje76IyktG7hUm3aaN6iV7w36BqteSNU.js?q933yp"
          />
          <script
            type="text/javascript"
            innerHTML='
<!--//--><!--[CDATA[//--><!--
jQuery.extend(Drupal.settings, {"basePath":"\/","pathPrefix":"","ajaxPageState":{"theme":"rovi","theme_token":"xitVcSJjebTesOPc22Vy5ExsyBIu9Te1nbdUDMe-keo","js":{"public:\/\/google_tag\/google_tag.script.js":1,"misc\/jquery.js":1,"misc\/jquery.once.js":1,"misc\/drupal.js":1,"public:\/\/languages\/es_4TvYutQLdy_bje76IyktG7hUm3aaN6iV7w36BqteSNU.js":1},"css":{"modules\/system\/system.base.css":1,"modules\/system\/system.menus.css":1,"modules\/system\/system.messages.css":1,"modules\/system\/system.theme.css":1,"modules\/comment\/comment.css":1,"modules\/field\/theme\/field.css":1,"modules\/node\/node.css":1,"modules\/search\/search.css":1,"modules\/user\/user.css":1,"sites\/all\/modules\/contrib\/views\/css\/views.css":1,"sites\/all\/modules\/contrib\/ctools\/css\/ctools.css":1}}});
//--><!--]]-->
'
          />
          <script innerHTML='
            window.digitalData = window.digitalData || [];
            digitalData.push({
              "event": "Page Loaded",
              "page": {
                "analyticsId": "UA-91750722-65",
                "brandName": "Rovi",
                "country": "es",
                "flattened": true,
                "hostname": "www.supositoriosrovi.es",
                "language": "es",
                "siteTier": "low",
              }
            });'
          />
          <script innerHTML="
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','globalDataLayer','GTM-PJSTS9Z');"
          />
        </Helmet>
        ;
      </React.Fragment>
    );
  }
}
